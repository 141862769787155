import React, { ReactNode } from 'react'

import 'twin.macro'
import { WebsiteButton as Button } from 'mx/components/section-components'
import { Contractor } from 'mx/featured-installers/contractors'

type ContractorCardProps = {
  item: Contractor
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}
const CardContainer: React.FC<{ children?: ReactNode | undefined }> = ({
  children,
}) => {
  return (
    <div tw="relative mb-20">
      <div>{children}</div>
    </div>
  )
}

const ContractorItemCard = ({ item, onClick }: ContractorCardProps) => {
  const regions = item.region?.split(',').map((region) => region.trim())
  return (
    <div tw="w-full h-[max-content]">
      <CardContainer>
        <div tw="w-80 md:w-full xl:w-64 h-full md:min-h-[20rem] px-4 border-l border-gray-300 flex-col justify-start items-start gap-6 inline-flex">
          <div tw="self-stretch h-16 flex-col justify-start items-start gap-2">
            <div tw="flex justify-start items-start inline-flex space-x-3">
              {regions?.map((r, index) => (
                <div
                  key={index}
                  tw="px-2 py-0.5 bg-white border border-gray-300 rounded-sm justify-start items-start"
                >
                  <div tw="text-gray-800 text-xs font-light leading-tight">
                    {r}
                  </div>
                </div>
              ))}
            </div>
            <div tw="text-gray-900 text-2xl font-light">{item.name}</div>
          </div>
          <div tw="self-stretch h-10 flex-col justify-start items-start flex">
            <div tw="text-[#5d5957] text-sm">Based In</div>
            <div tw="text-gray-900 text-sm">{item.basedIn}</div>
          </div>
          <div tw="self-stretch h-10 flex-col justify-start items-start flex">
            <div tw="text-gray-600 text-sm">Willingness to travel</div>
            <div tw="text-gray-900 text-sm">{item.statedServiceArea}</div>
          </div>
          <Button color="transparentBlack" rounded onClick={onClick}>
            See Profile
          </Button>
        </div>
      </CardContainer>
    </div>
  )
}

export default ContractorItemCard

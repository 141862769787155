import { graphql, useStaticQuery } from 'gatsby'

export const useContractorItems = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulFeaturedInstaller(
        sort: { fields: name, order: ASC }
      ) {
        data: nodes {
          id
          basedIn
          companyName
          email
          featuredProjectsCustomerName
          featuredProjectsPhotos {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [WEBP]
                  quality: 100
                )
              }
            }
          }
          featuredProjectsQuote {
            featuredProjectsQuote
          }
          name
          phoneNumber
          region
          statedServiceArea
          website
          willingToTravel
        }
      }
    }
  `)
  return content
}

import React, { useState } from 'react'

import { IGatsbyImageData } from 'gatsby-plugin-image'
import tw from 'twin.macro'

import fireEvent from 'context/tracking/events'
import { WebsiteButton as Button } from 'mx/components/section-components'
import ContractorItemCard from 'mx/featured-installers/contractor-item-card'

export interface Contractor {
  basedIn?: string
  companyName?: string
  email?: string
  featuredProjectsCustomerName?: string
  featuredProjectsPhotos?: {
    title?: string
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }[]
  featuredProjectsQuote?: {
    featuredProjectsQuote?: string
  }
  name?: string
  phoneNumber?: string
  region?: string
  statedServiceArea?: string
  website?: string
  willingToTravel?: string
}

type ContractorsProps = {
  items: Contractor[]
  handleItemClick: (
    item: Contractor,
    setCurrentItem: React.Dispatch<React.SetStateAction<Contractor | null>>,
  ) => () => void
  setCurrentItem: React.Dispatch<React.SetStateAction<Contractor | null>>
}

const Contractors = ({
  items,
  handleItemClick,
  setCurrentItem,
}: ContractorsProps) => {
  const itemsPerPage = 12
  const lastPage = (items.length || 0) / itemsPerPage
  const [page, setPage] = useState(1)

  const loadMore = () => {
    setPage(page + 1)
  }

  return (
    <div tw="w-full max-w-screen-xl h-full max-h-full pb-12 w-full mx-auto sm:(pt-0 mt-12) md:(pt-4 mt-0 overflow-y-auto)">
      <div tw="flex items-center justify-start py-20 px-10 my-12 max-md:bg-gray-light">
        <p tw="text-xl md:text-2xl text-gray-700">
          {items?.length} {items?.length === 1 ? 'Contractor' : 'Contractors'}
        </p>
      </div>
      {items.length > 0 && (
        <>
          <div
            tw="grid grid-cols-1 gap-4 px-4 w-full md:(grid-cols-3) lg:(grid-cols-4) h-[max-content]"
            css={[page >= lastPage && tw`mb-32`]}
          >
            {items
              ?.slice(0, itemsPerPage * page)
              .map((item: Contractor) => (
                <ContractorItemCard
                  key={item.name}
                  item={item}
                  onClick={handleItemClick(item, setCurrentItem)}
                />
              ))}
          </div>
          {page < lastPage && (
            <div tw="w-full flex place-content-center mb-8 md:my-6">
              <Button
                color="transparentBlack"
                rounded
                onClick={() => {
                  fireEvent({
                    type: 'cta_clicked',
                    ctaStr: 'Load More',
                  })
                  loadMore()
                }}
              >
                View More
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Contractors

import React, { useEffect, useState } from 'react'

import tw from 'twin.macro'

import { Contractor } from 'mx/featured-installers/contractors'

type RenderCallbackArgs = {
  currentItem: Contractor | null
  filteredItems: Contractor[]
  setCurrentItem: React.Dispatch<React.SetStateAction<Contractor | null>>
}

type FilterLayoutProps = {
  children: (args: RenderCallbackArgs) => React.ReactNode
  items: Contractor[]
  openModal?: React.Dispatch<React.SetStateAction<boolean | false>>
}

const FilterLayout = ({ children, items, openModal }: FilterLayoutProps) => {
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null)
  const [currentItem, setCurrentItem] = useState<Contractor | null>(null)
  const regions = Array.from(
    new Set(
      items.flatMap(
        (item: Contractor) =>
          item.region?.split(',').map((region) => region.trim()),
      ),
    ),
  )

  useEffect(() => {
    if (window.location.hash !== '') {
      const decodedHash = decodeURIComponent(window.location.hash).substring(1)
      const item = items?.find((e) => {
        const name = e.name?.trim().toLowerCase().replaceAll(' ', '-')
        return name === decodedHash
      })
      setCurrentItem(item ?? null)
      openModal?.(!!item)
    } else {
      setCurrentItem(null)
      openModal?.(false)
    }
  }, [items])

  const handleRegionChange = (region: string | null) => {
    setSelectedRegion(region)
  }

  const filteredItems = selectedRegion
    ? items.filter(
        (item: Contractor) =>
          item.region
            ?.split(',')
            .map((region) => region.trim())
            .includes(selectedRegion),
      )
    : items

  return (
    <>
      <div tw="flex flex-wrap justify-evenly md:justify-center items-center space-x-6 mt-20 md:mt-32 mx-4">
        <button
          tw="h-11 w-32 text-gray-500 hover:text-white text-base rounded-full mt-8 bg-gray-light hover:bg-gray-500"
          css={[selectedRegion === null && tw`!bg-gray-500 !text-white`]}
          onClick={() => handleRegionChange(null)}
        >
          All
        </button>
        {regions.map((region) => (
          <button
            key={region}
            tw="h-11 w-32 text-gray-500 hover:text-white text-base rounded-full mt-8 !ml-0 md:!ml-6 bg-gray-light hover:bg-gray-500"
            css={[selectedRegion === region && tw`!bg-gray-500 !text-white`]}
            onClick={() => handleRegionChange(region ?? null)}
          >
            {region}
          </button>
        ))}
      </div>
      <div>{children({ currentItem, filteredItems, setCurrentItem })}</div>
    </>
  )
}

export default FilterLayout

import React, { useCallback, useEffect, useState } from 'react'

import 'twin.macro'
import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import { useContractorItems } from 'content-queries/contractor-items'
import { useFeaturedContractors } from 'content-queries/mx/featured-contractors'
import fireEvent from 'context/tracking/events'
import { BasicContent, MainBanner, MainContent } from 'mx/components/new'
import ContractorModal from 'mx/featured-installers/contractor-modal'
import Contractors, { Contractor } from 'mx/featured-installers/contractors'
import FilterLayout from 'mx/featured-installers/filter-layout'
import Layout from 'mx/layout'
import { scrollToTop } from 'views/utils'

const FeaturedContractorsPage = () => {
  const { data: items } = useContractorItems()
  const { data: data } = useFeaturedContractors()
  const [modalOpen, setModalOpen] = useState(false)
  const [
    {
      node: {
        sectionModules: [hero, learnMore, banner],
      },
    },
  ] = data

  useEffect(() => {
    scrollToTop()
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'FORM Featured Installers',
    })
  }, [])

  const handleOpenModal = useCallback(
    (
      item: Contractor,
      setCurrentItem: React.Dispatch<React.SetStateAction<Contractor | null>>,
    ) =>
      () => {
        fireEvent({
          type: 'cta_clicked',
          ctaStr: `See Profile ${item.name ?? ''}`,
        })
        setCurrentItem(item)
        window.location.hash = item.name
          ?.trim()
          .toLowerCase()
          .replaceAll(' ', '-') as string
        setModalOpen(true)
      },
    [],
  )

  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
    const { pathname, search } = window.location
    history.replaceState(undefined, document.title, pathname + search)
  }, [])

  return (
    <Layout>
      <MainContent header data={hero} classname={tw`!bg-black !text-white`} />
      <BasicContent
        data={learnMore}
        isCenterAligned
        classname={tw`my-20 md:mt-32 !text-left`}
      />
      <FilterLayout
        items={(items as Contractor[]).map((i) => ({
          ...i,
        }))}
        openModal={setModalOpen}
      >
        {({ currentItem, filteredItems, setCurrentItem }) => (
          <>
            <Contractors
              items={filteredItems}
              handleItemClick={handleOpenModal}
              setCurrentItem={setCurrentItem}
            />
            <ContractorModal
              item={currentItem as Contractor}
              open={modalOpen}
              handleClose={handleCloseModal}
            />
          </>
        )}
      </FilterLayout>
      <MainBanner data={banner} classname={tw`mt-20 lg:mt-32`} />
    </Layout>
  )
}
export default FeaturedContractorsPage

export const Head = () => {
  const { data } = useFeaturedContractors()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/featured-contractors"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}

import React from 'react'

import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import tw from 'twin.macro'

function NextArrow(props: any) {
  const { onClick } = props
  return (
    <div
      tw="z-40 right-3 absolute cursor-pointer flex items-center justify-center rounded-full w-8 h-8 text-gray-700 bottom-[-3.25rem] md:(top-auto bottom-[-4rem] right-0 w-10 h-10)"
      onClick={onClick}
    >
      <ArrowRightIcon color="currentColor" width={20} height={20} />
    </div>
  )
}

function PrevArrow(props: any) {
  const { onClick } = props
  return (
    <div
      tw="z-40 left-4 right-15 absolute cursor-pointer flex items-center justify-center rounded-full w-8 h-8 text-gray-700 bottom-[-3.25rem] md:(w-10 h-10 top-auto left-auto bottom-[-4rem])"
      onClick={onClick}
    >
      <ArrowLeftIcon color="currentColor" height={20} width={20} />
    </div>
  )
}

type ContractorModalSliderGallery = {
  title?: string
  localFile?: {
    childImageSharp?: {
      gatsbyImageData?: IGatsbyImageData
    }
  }
}

type ContractorModalSliderProps = {
  items: ContractorModalSliderGallery[]
}

const ContractorModalSlider = ({ items }: ContractorModalSliderProps) => {
  const settings = {
    speed: 500,
    arrows: true,
    dots: true,
    fade: true,
    focusOnSelect: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    appendDots: (dots: any) => (
      <ul tw="!bottom-[-3.5rem] md:!bottom-[-4rem] !pointer-events-none">
        {dots}
      </ul>
    ),
  }

  return (
    <div tw="flex flex-col justify-center w-full h-full">
      <div tw="grow">
        <Slider {...settings}>
          {items?.map((item: ContractorModalSliderGallery, index: number) => {
            const {
              localFile: {
                childImageSharp: {
                  gatsbyImageData: { height, width },
                },
              },
            } = item as any
            return (
              <div key={index} tw="h-full">
                <GatsbyImage
                  alt={`${item.title}`}
                  css={tw`max-h-48 h-full w-full md:(max-h-72) xl:max-h-96`}
                  imgStyle={{
                    ...tw`max-w-full w-full`,
                    objectFit: height >= width ? 'contain' : 'cover',
                    objectPosition: 'center',
                  }}
                  image={
                    item.localFile?.childImageSharp
                      ?.gatsbyImageData as IGatsbyImageData
                  }
                />
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default ContractorModalSlider

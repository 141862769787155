import React from 'react'

import * as DialogPrimitive from '@radix-ui/react-dialog'
import * as Portal from '@radix-ui/react-portal'
import { AnimatePresence, motion } from 'framer-motion'
import { useMedia } from 'react-use'
import tw, { styled } from 'twin.macro'

import ContractorModalSlider from './contractor-modal-slider'
import { Contractor } from './contractors'

const StyledOverlay = styled(DialogPrimitive.Overlay)(() => [
  tw`fixed inset-0 bg-black/25 z-40 flex flex-col justify-center items-center`,
])

const StyledContent = styled(DialogPrimitive.Content)(() => [
  tw`fixed inset-0 bg-white h-screen pb-0  rounded-sm w-full focus:outline-none z-50 isolate`,
  tw`md:(w-[90%] max-w-[1440px] h-[max-content] max-h-[730px] flex mx-auto -translate-y-2/4 top-1/2 overflow-hidden)`,
])

const DialogClose = DialogPrimitive.Close

const DialogContent = ({
  children,
  ...props
}: DialogPrimitive.DialogContentProps) => {
  const isMobile = useMedia('(max-width: 767px)')

  return (
    <Portal.Root>
      <StyledOverlay asChild forceMount>
        <motion.div
          id="contractorModalBackdrop"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'easeIn' }}
        />
      </StyledOverlay>
      <StyledContent {...props} asChild forceMount>
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              transform: 'translateY(20px)',
            },
            visible: {
              opacity: 1,
              transform: `translateY(${isMobile ? '0px' : '-50%'})`,
              transition: {
                duration: 0.25,
                ease: 'easeInOut',
              },
            },
          }}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          {children}
        </motion.div>
      </StyledContent>
    </Portal.Root>
  )
}

type ContractorModalProps = {
  closeOnClickOutside?: boolean
  closeOnEscape?: boolean
  description?: string
  handleClose: () => void
  open: boolean
  item: Contractor
}
const ContractorModal = ({
  closeOnClickOutside = true,
  closeOnEscape = true,
  item,
  handleClose,
  open,
}: ContractorModalProps) => {
  const isMobile = useMedia('(max-width: 767px)')
  const regions = item?.region?.split(',').map((region) => region.trim())

  return (
    <DialogPrimitive.Root open={open}>
      <AnimatePresence>
        {open ? (
          <DialogContent
            onEscapeKeyDown={closeOnEscape ? handleClose : undefined}
            onPointerDownOutside={closeOnClickOutside ? handleClose : undefined}
            style={
              isMobile
                ? {
                    height: 'calc(var(--vh, 1vh) * 100)',
                  }
                : undefined
            }
          >
            <DialogClose asChild onClick={handleClose}>
              <button tw="p-4 text-gray-900 absolute right-0 top-0 outline-none z-10">
                Close
              </button>
            </DialogClose>
            <div tw="flex flex-col h-full md:(w-full h-[unset])">
              <div tw="overflow-y-auto relative flex flex-col grow md:(w-full mr-3)">
                <div
                  tabIndex={-1}
                  tw="fixed left-0 w-full h-5 bg-gradient-to-b from-white to-transparent md:hidden"
                />
                <div tabIndex={-1} tw="h-2 md:hidden" />
                <div tw="p-8 md:p-12 pb-24 bg-gray-light flex-col justify-start items-center flex grow">
                  <div tw="self-stretch flex-col justify-center items-start gap-3 flex">
                    <div tw="justify-start items-start inline-flex space-x-4">
                      {regions?.map((r, index) => (
                        <div
                          key={index}
                          tw="px-2 py-0.5 bg-white border-[0.5px] border-gray-100 rounded-sm justify-center items-center flex"
                        >
                          <div tw="text-gray-800 text-xs font-light leading-tight">
                            {r}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div tw="w-full text-black text-3xl font-thin leading-9">
                      {item.name}
                    </div>
                    <div tw="w-full text-gray-500 text-lg font-light leading-tight">
                      {item.companyName}
                    </div>
                  </div>
                  <div tw="w-full relative border-b-[0.5px] border-gray-700 my-9" />
                  <div tw="self-stretch justify-between items-center md:(flex flex-row) lg:justify-start">
                    <div tw="w-full md:w-5/12 self-stretch flex-col justify-start items-start gap-6 inline-flex">
                      <div tw="justify-start items-start gap-2 inline-flex">
                        <div tw="text-center text-gray-600 text-[1.25rem] font-thin leading-relaxed">
                          Contact Info
                        </div>
                      </div>
                      <div tw="self-stretch h-full flex-col justify-start items-start gap-6 flex">
                        {item.phoneNumber && (
                          <div tw="justify-start items-center gap-4 inline-flex">
                            <div tw="text-gray-800 text-lg font-light leading-tighter underline">
                              <a
                                href={`tel:${item.phoneNumber}`}
                                target="_blank"
                              >
                                {item.phoneNumber}
                              </a>
                            </div>
                          </div>
                        )}
                        {item.email && (
                          <div tw="justify-start items-center gap-4 inline-flex">
                            <div tw="text-gray-800 text-lg font-light leading-tighter underline">
                              <a href={`mailto:${item.email}`} target="_blank">
                                {item.email}
                              </a>
                            </div>
                          </div>
                        )}
                        {item.website && (
                          <div tw="justify-start items-center gap-4 inline-flex">
                            <div tw="text-gray-800 text-lg font-light leading-tighter underline">
                              <a href={item.website} target="_blank">
                                {item.website}
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div tw="w-full md:w-5/12 mt-10 md:m-0 flex-col justify-start items-start gap-6 inline-flex">
                      <div tw="justify-start items-start gap-2 inline-flex">
                        <div tw="text-center text-gray-600 text-[1.25rem] font-thin leading-relaxed">
                          Service Area
                        </div>
                      </div>
                      <div tw="justify-start items-center gap-4 inline-flex">
                        <div tw="text-gray-800 text-lg font-light leading-tighter">
                          {item.basedIn}
                        </div>
                      </div>
                      {item.willingToTravel && (
                        <div tw="self-stretch text-gray-800 text-xl leading-normal">
                          “{item.willingToTravel}”
                        </div>
                      )}
                    </div>
                  </div>
                  {item.featuredProjectsPhotos && (
                    <div tw="self-stretch h-full flex-col justify-center items-center gap-6 flex mt-9">
                      <div tw="self-stretch justify-center flex-row">
                        <div tw="grow shrink items-baseline mb-6 py-1 border-b-[0.5px] border-gray-700 justify-start items-start flex">
                          <div tw="text-gray-500 text-xl font-thin">
                            FORM Projects showcase
                          </div>
                        </div>
                        <div tw="w-full md:(flex flex-row)">
                          <div tw="w-full w-full md:w-7/12 xl:w-1/2">
                            <div tw="mt-6 mb-20 md:mb-8">
                              <ContractorModalSlider
                                items={item.featuredProjectsPhotos}
                              />
                            </div>
                          </div>
                          <div tw="justify-center relative w-full md:w-5/12 xl:w-1/2 md:(m-auto)">
                            <div tw="flex flex-col justify-center m-auto gap-8 md:pl-6">
                              <div tw="flex flex-row w-full md:(flex-col)">
                                <div tw="font-thin text-gray-800 text-xl md:text-lg leading-normal my-auto">
                                  {
                                    item.featuredProjectsQuote
                                      ?.featuredProjectsQuote
                                  }
                                </div>
                              </div>
                              <div tw="flex flex-col justify-start items-start self-start lg:(pr-4)">
                                <p tw="text-gray-500 text-xs font-sans font-light leading-normal">
                                  {item.featuredProjectsCustomerName}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
        ) : null}
      </AnimatePresence>
    </DialogPrimitive.Root>
  )
}

export default ContractorModal
